import React from 'react'
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
const QualityControl = () => (
  <StaticQuery
    render={data => (
      <Layout>
        <Img fluid={data.qualityControlFluid.childImageSharp.fluid} />
        <h1 id="titlequalitycontrol">Quality Control</h1>

        <h1 id="mission">
          <strong>Mission</strong>
        </h1>

        <p>
          <strong>Duato’s</strong> mission is to by your eyes in China
          protecting your interests from suppliers research product development,
          production, quality control, cargo inspections, export/import to
          product delivery, acting as your own delegation here.
        </p>

        <h1 id="services">
          <strong>Services</strong>
        </h1>

        <p>
          <strong>Duato’s</strong> procedure are implement according with the
          western business requirements and international standards; our
          technical team. European engineers has a demonstrated strong
          experience and know-how in production, labs, R&amp;D, market research.
        </p>

        <p>
          <strong>
            DUATO WILL SUPPORT YOU AT EACH STAGE OF THE ACQUISITION A PRODUCT OR
            MATERIAL: SOURCING &amp; FOLLOW UP THE PRODUCTION PRODUCT AND
            SUPPLIER RESEARCH
          </strong>{' '}
          Product and suppliers research according to the client’s technical
          specifications, quality and target cost Market study factories visit
          and technical audit Contract negotiation: production, delivery, price
          and terms Production/sourcing{' '}
          <strong>PRODUCT DEVELOPMENT &amp; INDUSTRIAL</strong> Design Product
          design and development according to technical specifications and
          drawing Samples development and approval <strong>ENGINEERING</strong>{' '}
          Research and development Technological assistance Production set up
          Productivity improvement
        </p>

        <p>
          <strong>
            QUALITY CONTROL (CERTIFIED COMPANY) \$ CARGO INSPECTION
          </strong>{' '}
          To warranty the correct quality of the goods – test and analysis
          directly in the factory – according to the client’s contractual
          specifications. Items/models, colors, size checking Quality control –
          analysis and technical tests To warranty that the shipped goods match
          with the original order Product accounting according to proforma
          invoice/original order Packaging checking, Packing list verification
          Loading Technical Report
        </p>

        <p>
          <strong>DUATO QUALITY CONTROL CERTIFICATE</strong> Our controls fit
          with an exhaustive technical process. After passing our tests and
          inspection, <strong>DUATO</strong> issues the{' '}
          <strong>QCC-QUALITY CONTROL CERTIFICATE</strong> to authorize the
          loading. Including the <strong>QCC</strong> in your Letter of Credit
          or original purchase order, you get the certification that goods have
          been inspected and found conformed to the contractual specifications.
          The <strong>QCC</strong> is completed by our technical report,
          including pictures to evidence the correct shipment of the goods.
        </p>
      </Layout>
    )}
    query={graphql`
      query {
        qualityControlFluid: file(
          relativePath: { eq: "slider/head-pic02.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
  />
)
export default QualityControl
